<template>
  <div v-if="isLoading" class="spinner spinner-download">
    <loading :active="isLoading" :is-full-page="true" color="#e47f44" />

  </div>
  <nav class="nav-bar" id="navbar">
    <section class="nav-bar__center">
      <img src="@/assets/svg/icons-icons-align-horizontal.svg" @click="switchView">
      <button class="btn-orange" @click="getScreenShot" v-if="profile && [1, 2].includes(profile.role)">
        Download pagina
      </button>
    </section>
    <section class="nav-bar__actions">
      <!-- {{ profile }} -->
      <!-- <BaseIcon
        class="nav-bar__icon"
        icon="notification"
        @click="$router.push({ name: 'notifications' })"
      /> -->
      <img class="nav-bar__actions__img"
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSegCgK5aWTTuv_K5TPd10DcJxphcBTBct6R170EamgcCOcYs7LGKVy7ybRc-MCwOcHljg&usqp=CAU">
      <span>{{ profile ? profile.email.split('@')[0].split('.')[0].charAt(0).toUpperCase(0) +
        profile.email.split('@')[0].split('.')[0].slice(1) : '' }}</span>
    </section>
  </nav>
</template>

<script>
import { useRoute } from 'vue-router';
import { UserApi } from '@/api';
import domtoimage from 'dom-to-image-more';
import { getCookie } from '@/utils/cookies';
import jwt_decode from 'jwt-decode';
import html2canvas from 'html2canvas';
import Loading from 'vue3-loading-overlay';
import { ref } from 'vue';

import jsPDF from 'jspdf';

export default {
  name: 'NavBar',
  components: {
    Loading
  },
  props: {
    switchView: {
      type: Function,
      default: () => { }
    }
  },
  data() {
    return {
      profile: '',
      route: useRoute(),
      isLoading: ref(false)
    };
  },
  mounted() {
    const jwt = getCookie('jwt');
    if (jwt === null) {
      return 4;
    }
    const { profile } = jwt_decode(jwt);
    this.profile = profile;
  },
  methods: {
    async getScreenShot() {
      this.isLoading = true;
      var node = document.getElementsByClassName('screen-page');
      const pdf = new jsPDF();
      const fileName = this.route.name === 'sviluppo' ? `${(new Date()).toISOString().split('T')[0]}SviluppoTurismo` : `${(new Date()).toISOString().split('T')[0]}Benchmarks`;
      let yPos = 0;
      for (const index in Array.from(node)) {
        await domtoimage
          .toCanvas(node[index])
          .then(function (canvas) {
            const imgData = canvas.toDataURL('image/png');

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
            const x = (pdfWidth - imgWidth * ratio) / 2; // center horizontally
            pdf.addImage(imgData, 'PNG', x, yPos, imgWidth * ratio, imgHeight * ratio,'','FAST',0.5);

            // Update current position on page
            yPos += imgHeight * ratio;

            // Check if there is enough space left on the page for another image
            if (yPos > pdf.internal.pageSize.height - imgHeight * ratio) {
              pdf.addPage();
              yPos = 0;
            }
          })
          .catch(async function (error) {
            const options = {
              backgroundColor: '#ffffff' // Set the background color to white
            };
            const canvas = await html2canvas(node[index], options);
            const imgData = canvas.toDataURL('image/png');

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
            const x = (pdfWidth - imgWidth * ratio) / 2; // center horizontally
            pdf.addImage(imgData, 'PNG', x, yPos, imgWidth * ratio, imgHeight * ratio);

            // Update current position on page
            yPos += imgHeight * ratio;

            // Check if there is enough space left on the page for another image
            if (yPos > pdf.internal.pageSize.height - imgHeight * ratio) {
              pdf.addPage();
              yPos = 0;
            }
          });
      }
      this.isLoading = false;
      pdf.save(`${fileName}.pdf`);

    },
    deleteCookie() {
      // Send a POST request
      UserApi.logout();
    }
  }
};
</script>

<style lang="scss" scoped>
.spinner-download {
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: rgba(80, 79, 79, 0.445);
}

.nav-bar {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  left: 0;
  padding: 22px 30px 22px 36px;
  position: relative;
  top: 0;

  .btn-orange {
    background-color: var(--main-color);
    border: none;
    color: white;
    cursor: pointer;
    font-weight: bold;
    padding: 10px 30px;
    border-radius: 100px;
    font-size: x-small;
    height: 50%;
    &:hover {
          background: #e47f44c9;
        }
  }

  >img {
    cursor: pointer;
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__center {
    display: flex;
    align-items: center;

    >img {
      padding: 10px;
      cursor: pointer;
      margin-left: -12px;
    }
  }

  &__icon {
    cursor: pointer;

    ~.nav-bar__icon {
      margin-left: 30px;
    }
  }

  &__select {
    position: relative;

    >* {
      user-select: none;
    }
  }

  &__select-header {
    align-items: center;
    background-color: var(--select-close-background);
    color: var(--select-selected-color);
    cursor: pointer;
    display: flex;
    padding: 1px 0;
    position: relative;

    >[data-icon="arrow-icon"] {
      margin-left: 30px;
      transition: all 0.3s ease-in-out;
    }

    &:focus {
      background-color: #fff;

      >[data-icon="arrow-icon"] {
        transform: rotate(-180deg);
      }

      .nav-bar__select-options {
        display: block;
      }
    }
  }

  &__select-options {
    display: none;
    font-weight: 400;
    left: 0px;
    min-width: 100%;
    position: absolute;
    top: 30px;
    width: max-content;

    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: left;
    }

    li {
      background-color: var(--select-option-background);
      border-radius: 4px;
      color: var(--select-selected-color);
      cursor: pointer;
      padding: 10px 26px;

      ~li {
        margin-top: 1px;
      }

      &:hover {
        background-color: var(--select-option-background-hover);
      }
    }
  }

  &__actions {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    &__img {
      height: 100%;
      width: 30px;
    }
  }
}
</style>
