const getCssVariable = (variable) => {
  // eslint-disable-next-line quotes
  const light = "[data-theme='light']";
  // eslint-disable-next-line quotes
  const dark = "[data-theme='dark']";

  const el = document.querySelector(light) || document.querySelector(dark);
  const style = getComputedStyle(el);

  return style.getPropertyValue(variable);
};

export default {
  getCssVariable
};
