import axios from 'axios';
import { getCookie } from '@/utils/cookies';

const instance = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: `https://${process.env.VUE_APP_BASE_DOMAIN}/v1` //ToDo
});

// Setting bearer from cookie before every api call
instance.interceptors.request.use((config) => {
  const jwt = getCookie('jwt');
  config.headers.Authorization = `Bearer ${jwt}`;

  return config;
});

export { UserApi } from './modules/user';

export default instance;
