export const getCookie = (name) => {
  // Formatting the cookie name adding the equal sign to it so that we can be sure to match a key -> value pair
  const nameWithEqualSymbol = name + '=';

  // Splitting the cookies retrived from the document and trimming every instance to left (removing spaces)
  const cookieList = document.cookie
    .split(';')
    .map((cookie) => cookie.trimLeft());

  // Looping through the list of cookies and check if any of them matches the one passed as argument to getCookie function
  for (let i = 0; i < cookieList.length; i++) {
    let cookie = cookieList[i];

    if (cookie.indexOf(nameWithEqualSymbol) === 0)
      // Once the searched cookie is found, make a substring for retrieving the value associated to it (the substring is necessary because the key -> value is saved as unique string)
      return cookie.substring(nameWithEqualSymbol.length, cookie.length);
  }
  return null;
};

export const setCookie = ({ cookieName, cookieValue, expiration = null }) => {
  let expires = '';
  // If a number of days is passed use it to set an expiration time for the new cookie
  if (expiration) {
    expires = `expires=${expiration}`;
  } else {
    // Setting one month expiration
    const today = new Date();
    today.setMonth(today.getMonth() + 1);

    expires = today;
  }

  document.cookie = `${cookieName}=${
    cookieValue || ''
  }; ${expires}; path=/;`;
};
