<template>
  <main>
    <div class="app__content">
      <HomePage />
    </div>
  </main>
</template>

<script>
import HomePage from '@/views/HomePage.vue';
// TODO add all the metrics on graphs
// TODO set all graph in backend in order to display 0
export default {
  name: 'App',
  components: {
    HomePage
  }
};
</script>

<style lang="scss">
// TODO: clear and unify the css 
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

main {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: 700;
  color: var(--app-text-color);
  text-align: center;
}

.app__content {
  display: flex;
  height: 100%;
  width: 100%;
}
</style>
