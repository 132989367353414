<template>
  <div :class="['sidebar', { 'sidebar--collapsed': isCollapsed }]" id="sidebar">
    <div class="sidebar__logo">
      <img v-if="!isCollapsed" src="@/assets/logo-esteso.svg">
      <img v-else src="@/assets/insight-logo-close.svg" class="svg-close">
    </div>
    <template v-for="(item, index) in menu" :key="`${index}__parent`">
      <div v-if="item.roles.includes(getRole())">
        <div :class="[
          'sidebar__item',
          {
            sidebar__item: item.children.length > 0,
            'sidebar__item--is-selected':
              currentRoute === item.value || $route.name === item.value,
          },
        ]" @click="handleParentClick(item)">
          <q-icon v-if="item.icon" size="sm" :name="item.icon"
            :color="isCollapsed || item.children.length === 0 ? '' : '#B7BFC7'" />
          <span data-type="label">{{ item.label }}</span>
        </div>
        <div v-if="item.children.length > 0">
          <div v-for="(child, _index) in item.children" :key="`${_index}__child`" :data-type="child.value" :class="[
            'sidebar__child',
            {
              'sidebar__child--is-selected':
                currentRoute === child.value || $route.name === child.value,
            },
          ]" @click="handleParentClick(child)">
            <span data-type="label">{{ child.label }}</span>
          </div>
        </div>
      </div>
    </template>
    <div class="logout" style="cursor: pointer;" @click="logout">
      <q-icon name="logout" />
      <p>
        Logout
      </p>
    </div>
  </div>
</template>

<script>
import helpers from '@/utils/helpers';
import { getCookie, setCookie } from '@/utils/cookies';
import jwt_decode from 'jwt-decode';
import { useStore } from 'vuex';

export default {
  name: 'SideBar',
  props: {
    isCollapsed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      store: useStore(),

      menu: [
        // {
        //   icon: 'home',
        //   label: 'Panoramica',
        //   value: 'overview',
        //   children: [],
        // },
        // {
        //   icon: 'view_column',
        //   label: 'Portali Verticali',
        //   value: 'portali-verticali',
        //   children: [
        //     {
        //       label: 'Sviluppo turismo Italia',
        //       value: 'sviluppo',
        //     },
        //     // {
        //     //   label: 'Altri competitor',
        //     //   value: 'competitors',
        //     // },
        //     {
        //       label: 'Benchmark e Classifiche',
        //       value: 'benchclass',
        //     },
        //   ],
        // },
        {
          icon: 'holiday_village',

          label: 'Sviluppo turismo Italia',
          value: 'sviluppo',
          roles: [1, 2, 3, 4],
          children: []
        },
        // {
        //   label: 'Altri competitor',
        //   value: 'competitors',
        // },
        {
          icon: 'leaderboard',
          roles: [1, 2],

          children: [],
          label: 'Benchmark e Classifiche',
          value: 'benchclass'
        }
        // {
        //   icon: 'campaign',
        //   label: 'Campagne',
        //   value: 'campagne',
        //   children: [
        //     {
        //       label: 'Analytics',
        //       value: 'analytics',
        //     },
        //     {
        //       label: 'Facebook',
        //       value: 'facebook',
        //     },
        //     {
        //       label: 'Google ADS',
        //       value: 'ads',
        //     },
        //   ],
        // },
        // {
        //   icon: 'manage_accounts',
        //   label: 'Gestionale',
        //   value: 'gestionale-page',
        //   children: [
        //     {
        //       label: 'Preventivi',
        //       value: 'preventivi',
        //     },
        //     {
        //       label: 'Prenotazioni',
        //       value: 'prenotazioni',
        //     },
        //   ],
        // },
      ],
      currentRoute: '',
      getCssVariable: helpers.getCssVariable
    };
  },
  methods: {
    logout() {
      setCookie({ cookieName: 'jwt', cookieValue: '' });
      this.store.commit('setAccomodation', undefined);
      this.store.commit('setRegion', undefined);

      this.$router.push({ name: 'login' });

    },
    getRole: () => {
      const jwt = getCookie('jwt');
      if (jwt === null) {
        return 4;
      }
      try {
        const { profile } = jwt_decode(jwt);
        return profile.role;
      } catch (error) {
        console.log(error);
      }
    },
    handleParentClick(item) {
      if (item.value == undefined) {
        return;
      }
      this.currentRoute = item.value;
      this.$router.push({ name: item.value, params: { area: item.value } });
    }
  }
};
</script>

<style lang="scss" scoped>
.logout {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: 30px;

  padding: 11px 0 0px 15%;

  & p {
    margin: 0;
  }
}

.sidebar--collapsed .logout {
  left: 0;
  right: 0;

  & p {
    display: none;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--sidebar-background);
  height: 100vh;
  font-weight: bold;
  border-radius: 0 30px 30px 0;

  &__logo {
    margin: 10% 0;

    img {
      max-width: 60%;
    }

    >.svg-close {
      padding: 10%;
      margin: 45px 22px;
    }
  }

  &__child {
    font-size: 13px;
    padding: 11px 0 0px 27%;
    text-align: left;
    color: var(--grey-text-color);

    &:first-child {
      padding-top: 0;
    }

    &:hover,
    &--is-selected {
      color: var(--main-color);
    }

    &:last-child {
      padding-bottom: 15px;
    }
  }

  &__child,
  &__item {
    color: var(--grey-text-color);
    cursor: pointer;

    &--is-selected {
      color: var(--main-color);
      background: linear-gradient(to right,
          var(--main-color) 0,
          var(--main-color) 0 3%,
          #0000 0 75%,
          #0000 75%);
    }
  }

  &--collapsed {
    flex: 1 0 54px;

    svg {
      margin-right: 12px;
    }

    .sidebar__child {
      display: none;
    }

    .sidebar__item {
      &>[data-type="label"] {
        display: none;
      }
    }

    .sidebar__toggle {
      right: 15px;
      transform: rotate(180deg);
    }
  }

  &__collapser {
    border: none;
    cursor: pointer;
    position: absolute;
    background: transparent;
    bottom: 0;
    right: 0;

    svg {
      margin: 0;
    }
  }

  &__item {
    align-items: center;
    display: flex;
    font-size: 13px;
    padding: 10px 29px 10px 29px;

    &:hover:not(.sidebar__item--is-parent) {
      background-color: var(--sidebar-parent-hover-background);
    }

    &--is-selected {
      color: var(--main-color);
    }

    &--is-parent {
      &>[data-type="label"] {
        color: var(--sidebar-parent-color);
      }
    }

    &>[data-type="label"] {
      margin-left: 13px;
    }
  }

  &>div:not(:first-child) {
    padding-bottom: 5%;
  }

  &__toggle {
    bottom: 30px;
    cursor: pointer;
    position: absolute;
    right: 30px;
  }
}
</style>
