import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
// Styles
import '@/styles/main.scss';


const app = createApp(App);
  app.use(router)
  .use(Quasar, quasarUserOptions)
  .use(store)
  .mount('#app');

  app.provide('AUTH0_DOMAIN', 'https://insight-up.eu.auth0.com/oauth/token');
  app.provide('AUTH0_CLIENT_ID', 'NpkJKsVFTdEqftvzLRRzyO0XJ85cNiRB');
  app.provide('AUTH0_CLIENT_SECRET', 'CvLP9S_Zmk-fonzmmgdUUdM5OpX6aipzRKKweAl3n_cNsxaOZp5eLr7tnA50fdl9');
  app.provide('AUTH0_AUDIENCE', 'https://insight-up.eu.auth0.com/api/v2/');
