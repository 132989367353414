import { createStore } from 'vuex';

export default createStore({
  state: {
    region: null,
    accomodation: null
  },
  getters: {},
  mutations: {
    setRegion(state, region) {
      state.region = region;
    },
    setAccomodation(state, accomodation) {
      state.accomodation = accomodation;
    }
  },
  actions: {},
  modules: {}
});
