import { createRouter, createWebHistory } from 'vue-router';
import { getCookie, setCookie } from '@/utils/cookies';
import jwt_decode from 'jwt-decode';

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      roles: [ 1, 2, 3, 4 ]
    },
    component: () =>
      import(/* webpackChunkName: 'LoginPage' */ '@/views/login/LoginPage.vue')
  },
  {
    path: '/set-password',
    name: 'set-password',
    meta: {
      roles: [ 1, 2, 3, 4 ]
    },
    component: () =>
      import(/* webpackChunkName: 'SetPassword' */ '@/views/login/SetPassword.vue')
  },
  {
    path: '/portali-verticali/sviluppo',
    name: 'sviluppo',
    meta: {
      roles: [ 1, 2, 3, 4 ]
    },
    component: () =>
      import(/* webpackChunkName: 'SviluppoTurismo' */ '@/views/portali/SviluppoTurismo.vue')
  },
  {
    path: '/portali-verticali/benchmark-classifiche',
    name: 'benchclass',
    meta: {
      roles: [ 1, 2 ]
    },
    component: () => import(
      /* webpackChunkName: 'BenchmarkClassifiche' */ '@/views/portali/BenchmarkClassifiche.vue'
      )
  },
  {
    path: '/:notFound(.*)',
    redirect: '/portali-verticali/sviluppo'
  }
];

const router = createRouter({
  // eslint-disable-next-line no-undef
  history: createWebHistory(process.env.BASE_URL),
  routes
});
router.onError(error => {
  console.error(error);
  console.error('Failure Reason: ', error.message, error);
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    console.error('Reloading Window 1');
    window.location.reload();
  }
  else if (/Loading.*chunk.*failed./i.test(error.message)) {
    console.error('Reloading Window 2');
    window.location.reload();
  }
});
router.beforeEach((to, from, next) => { 
  // Retrieving the bearer token from the url
  const bearer_token = to.query.__bearer_access_token;
  let decoded_token;

  // /**
  //  * If the bearer token exists, it means we are redirecting from the login page so we need to:
  //  * 1. set the cookie
  //  * 2. redirect to home page
  //  */
  if (bearer_token) {
    decoded_token = jwt_decode(bearer_token);
    // 1.
    const expiration = decoded_token.exp;
    setCookie({ cookieName: 'jwt', cookieValue: bearer_token, expiration });

    // 2.
    next({
      name: 'sviluppo'
    });

    return;
  }

  // /**
  //  * If the cookies is not set, and we are not navigating to the login page, we need to redirect the user
  //  * to the login page
  //  */

  const jwt = getCookie('jwt');
  if (!jwt && to.name !== 'login') {
    next({
      name: 'login'
    });
    return;
  }
  if (to.name === 'login') {
    next();
  }
  try {
    const { profile } = jwt_decode(jwt);
    // const decoded = jwt.verify(token, 'secret')
    if (to.meta.roles.includes(profile.role)) {
      next();
    } else {
      next('/login');
    }
  } catch (error) {
    return next({ name: 'login' });
  }
  // next();
});

export default router;
