import instance from '../index';

export const UserApi = {
  logout() {
    return instance.get('/logout');
  },
  login() {
    return instance.get('/login');
  }
};
