<template>
  <div class="storeLayout">
    <div
      v-if="$route.name !== 'login' && $route.name !== 'set-password'"
      class="storeLayout__side"
    >
      <Sidebar :is-collapsed="show" />
    </div>
    <div class="storeLayout__frame">
      <Navbar
        v-if="$route.name !== 'login' && $route.name !== 'set-password'"
        :switch-view="switchView"
      />
      <router-view/>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Sidebar from '@/components/Sidebar.vue';

export default {
  name: 'HomePage',
  components: { Sidebar, Navbar },
  data() {
    return {
      show: false
    };
  },
  methods: {
    switchView() {
      this.show = !this.show;
    }
  }
};
</script>

<style lang="scss">
.storeLayout {
  display: flex;
  width: 100%;
  height: 100%;
  &__frame {
    width: 100%;
  }
  &__side {
    height: 100%;
    width: 300px;
    position: relative;
    &:has(.sidebar--collapsed) {
      width: 5%;
    }
    &:has(.sidebar--collapsed) > .sidebar--collapseds {
      width: fit-content;
    }
  }
}
</style>
